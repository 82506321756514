import {
  ToggleButtonGroup,
  FormControl,
  TextField,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from 'src/components/Iconify';

import { parseRTSPUrl } from 'src/utils/parseRTSPUrl';
import CardToggleButton from 'src/components/ftp/CardToggleButton';
import TabContainer from '../../../../../components/ftp/TabContainer';
import { formConfig } from '../formConfig';

// App
export default function ConnectionType({ form }) {
  const theme = useTheme();
  const validRTSP = parseRTSPUrl(form.data.rtspUrl);

  // Handle when any of the form values relating to the RTSP form are changed
  const handleRTSPFieldUpdate = (formKey, formValue) => {
    // Update RTSP field value
    form.setData((prev) => {
      const newFormData = {
        ...prev,
        [formKey]: formValue,
      };

      newFormData.rtspUrl = encodeURI(`rtsp://${encodeURIComponent(newFormData.username)}:${encodeURIComponent(newFormData.password)}@${newFormData.ipAddress}:${newFormData.port}/${newFormData.streamPath}`);

      return newFormData;
    });
  };

  // Handle when RTSP URL changes
  const updateRTSPChange = (newValue) => {
    // Parse RTSP data
    const rtsp = parseRTSPUrl(newValue);

    // Check if RTSP is valid
    if (rtsp) {
      // Update form values
      form.setData((prev) => ({
        ...prev,
        ipAddress: rtsp.ip,
        port: rtsp.port,
        streamPath: rtsp.path,
        username: rtsp.username,
        password: rtsp.password,
      }));
    }

    // Update rtspUrl field value
    form.setData((prev) => ({
      ...prev,
      rtspUrl: newValue,
    }));
  };

  // const clearCredentials = () => {
  //   form.setData((prev) => ({
  //     ...prev,
  //     username: '',
  //     password: '',
  //   }));
  // };

  return (
    <TabContainer>
      {/* Connection Types (IP/DVR) */}
      <ToggleButtonGroup
        sx={{ display: 'flex', gap: 3, bgcolor: 'transparent', border: 'none' }}
        value={form.data?.[formConfig.connectionType.id]}
        exclusive
        onChange={(e) => form.setData((prev) => ({ ...prev, [formConfig.connectionType.id]: e.currentTarget.value }))}
        fullWidth
      >
        {formConfig.connectionType.list.map((type) => (
          <CardToggleButton
            key={type.id}
            value={type.id}
            icon={
              <Iconify
                icon={type.icon}
                color={form.data?.connectionType === type.id ? theme.palette.primary.main : theme.palette.grey[500]}
                width={26}
                height={26}
              />
            }
            label={type.label}
          />
        ))}
      </ToggleButtonGroup>

      <Stack direction="column" spacing={{ xs: 2, sm: 3 }} mt={3}>
        {/* IP and Port */}
        {form.data?.connectionType === 'ip' && (
          <>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 3 }}>
              <FormControl fullWidth>
                <TextField
                  label={'IP Address'}
                  type="text"
                  placeholder={'192.168.1.100'}
                  value={form.data?.ipAddress || ''}
                  onChange={(e) => handleRTSPFieldUpdate('ipAddress', e.target.value)}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  label="Port"
                  type="number"
                  placeholder="8080"
                  value={form.data?.port || ''}
                  onChange={(e) => handleRTSPFieldUpdate('port', e.target.value)}
                />
              </FormControl>
            </Stack>

            {/* Username and Password */}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 3 }}>
              <FormControl fullWidth>
                <TextField
                  label="Username"
                  type="text"
                  placeholder="Username"
                  value={form.data?.username || ''}
                  onChange={(e) => handleRTSPFieldUpdate('username', e.target.value)}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  label="Password"
                  type="password"
                  placeholder="Password"
                  value={form.data?.password || ''}
                  onChange={(e) => {
                    handleRTSPFieldUpdate('password', e.target.value);
                  }}
                />
              </FormControl>
            </Stack>

            {/* RTSP Path */}
            <Stack>
              <FormControl fullWidth>
                <TextField
                  label="Stream Path"
                  type="text"
                  placeholder="Streaming/Channels/1"
                  value={form.data?.streamPath || ''}
                  onChange={(e) => {
                    handleRTSPFieldUpdate('streamPath', e.target.value);
                  }}
                />
              </FormControl>
            </Stack>

            {/* RTSP Field */}
            <Stack>
            <FormControl fullWidth>
                <TextField
                  label="RTSP URL"
                  type="text"
                  error={!!form.data.rtspUrl && !validRTSP}
                  helperText={!validRTSP && !!form.data.rtspUrl && 'Invalid RTSP URL'}
                  value={form.data?.rtspUrl || ''}
                  onChange={(e) => updateRTSPChange(e.target.value)}
                  placeholder="rtsp://username:password@192.168.1.10:554/Streaming/Channels/1"
                  inputProps={{
                    "data-testid": "camera-rtsp-input"
                  }}
                />
              </FormControl>
            </Stack>
          </>
        )}
      </Stack>

      {/* Buttons Row */}
      {/* <Stack display={'flex'} direction="row" justifyContent={'space-between'}>
        <Button
          onClick={clearCredentials}
          startIcon={
            <Iconify icon={'material-symbols:password'} color={theme.palette.primary.main} width={20} height={20} />
          }
          sx={{ width: 'fit-content' }}
        >
          Use My Own Credentials
        </Button>
        <Button
          startIcon={<Iconify icon={'mdi:plug'} color={theme.palette.primary.main} width={20} height={20} />}
          sx={{ width: 'fit-content' }}
        >
          Test Connection
        </Button>
      </Stack> */}
    </TabContainer>
  );
}
