import { Stack } from '@mui/material';
import React from 'react';

const NotConnectedDevice = ({ hasLostPermission, onPermissionChange, onSubscribe, loading }) => (
  <Stack spacing={2}>
    {/* {hasLostPermission && (
      <Alert severity="error">
        <Typography variant="caption" align="center" sx={{ color: 'error.main' }}>
          ⚠️ Vous ne recevez plus les notifications sur cet appareil ⚠️
        </Typography>
      </Alert>
    )}
    {!hasLostPermission && (
      <Alert
        severity="warning"
        sx={{
          borderRadius: (theme) => theme.spacing(1.5),
        }}
      >
        <Typography variant="caption" align="center">
          Vous n'avez pas autorisé les notifications sur cet appareil
        </Typography>
      </Alert>
    )}
    <AuthorizeButton loading={loading} onSubscribe={onSubscribe} onPermissionChange={onPermissionChange} /> */}
  </Stack>
);

export default NotConnectedDevice;
