/**
 * A simple function which returns sliced text with "..." in the middle
 * 
 * @param {object} props
 * @param {string} props.text - Input text
 * @param {number} [props.length] - Length of slices (default=4)
 * @returns {React.ReactNode} Sliced component
 * 
 * @example
 * <SlicedString string="Thisisasuperlongstring" />
 * 
 * Output: "This...ring"
 */
export default function SlicedText({ text, length=4 }) {
    return (
        <>
            {text.slice(0, length)}<span style={{ opacity: 0.5 }}>...</span>{text.slice(-length)}
        </>
    );
}
