import {
  FormControl,
  TextField,
  Stack,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import TabContainer from '../../../../../components/ftp/TabContainer';
import { formConfig } from '../formConfig';

// Utility function
export function randomInArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}

// App
export default function Details({ form }) {
  return (
    <TabContainer>
      <Stack direction="column" spacing={{ xs: 2, sm: 3 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 3 }}>
          <FormControl fullWidth>
            <TextField
              autoFocus
              label="Nom caméra"
              type="text"
              placeholder={randomInArray(['Aisle 5', 'Front Door', 'Cash Register', 'Main Entrance', 'Front Door'])}
              value={form.data.name}
              onChange={(e) => form.setData((prev) => ({ ...prev, name: e.target.value }))}
              inputProps={{
                 "data-testid": "camera-name-input"
              }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ display: "none" }}> {/* CHANGE ME */}
            <InputLabel>{formConfig.location.label}</InputLabel>
            <Select
              defaultValue=""
              value={form.data?.location}
              label="Location"
              onChange={(e) => form.setData((prev) => ({ ...prev, location: e.target.value }))}
            >
              {formConfig.location.list.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </TabContainer>
  );
}
