import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ApiService from 'src/services/api';
import SubpageAppBar from 'src/components/ftp/SubpageAppBar';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import { useNavigate, useParams } from 'react-router-dom';

// Steps
import { ConnectionType, Details, License, Machine } from './steps';

// App
export default function NewCamera() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    connectionType: '',
    username: '',
    password: '',
    port: '',
    ipAddress: '',
    streamPath: '',
    assignedStore: id,
    rtspUrl: '',
    machine: '',
  });

  useEffect(() => {
    console.table(formData);
  }, [formData]);
  
  useEffect(() => {
    const fetchStore = async () => {
      const defaults = await ApiService.fetchStore(id);
      setFormData((prev) => ({
        ...prev,
        username: '', // TODO: Use the previously used username/password
        password: ''  //       This is easy to set up, just use the "defaults" variable which should
                      //       contain the previously used credentials, then pass those instead of
                      //       these empty strings ('').
      }));
    }

    fetchStore();
  }, [id]);

  const handleSubmission = async () => {
    // Validate identifier
    // TODO: Add some sort of client-side validation here
    //       which could be simply checking if all fields
    //       are !== '' or something better, like (Regex)
    
    // if (!formData?.name) return;
    // if (!formData.ipAddress) throw Error("ipAddress is missing")

    // Set loading state
    setLoading(true);

    try {
      // Submit data
      const camera = await ApiService.addCamera(id, formData);
      
      await ApiService.patchCamera({
        cameraId: camera.cameraId,
        name: formData.name,
        machineId: formData.machine,
        cameraType : formData.connectionType,
        streamUrl: formData.rtspUrl
      })

      // Complete! Navigate back
      navigate(`/stores/${id}`);
      
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* App bar */}
      <SubpageAppBar showBackButton pageName={'New Camera'} />

      <Page title="New Camera">
        <Container maxWidth={false}>
          <HeaderBreadcrumbs heading="Connect a Camera" links={[{ name: `Create & connect a new camera` }]} />

          {/* Form Steps */}
          <form>
            <Stack spacing={3}>
              <Details form={{ data: formData, setData: setFormData }} />

              <Divider />

              <ConnectionType form={{ data: formData, setData: setFormData }} />

              <Divider />

              <Machine form={{ data: formData, setData: setFormData }} />

              <Divider />

              <License form={{ data: formData, setData: setFormData }} />

              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Divider />
              </Box>

              {/* Row 2 - Test Connection */}
              <Stack display={'flex'} flexDirection="row" gap={1} justifyContent={{ xs: 'center', sm: 'end' }}>
                <LoadingButton
                  loading={loading}
                  onClick={handleSubmission}
                  loadingPosition="start"
                  startIcon={<Iconify icon="fluent:tab-add-20-filled" color="#fff" width={20} height={20} />}
                  variant="contained"
                  data-testid="add-camera-form-button"
                  >
                  Add Camera
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Container>
      </Page>
    </>
  );
}

function Divider() {
  const theme = useTheme();

  return (
    <Box display={'flex'} justifyContent={'center'} fullWidth>
      <Iconify icon="bxs:down-arrow" color={theme.palette.grey[300]} width={22} height={22} />
    </Box>
  );
}
