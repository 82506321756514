import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField, Button } from '@mui/material';
import _ from 'lodash';
import Iconify from '../../../../components/Iconify';
import StoreForm from '../form/StoreForm';

StoreTableToolbar.propTypes = {
  onFilterName: PropTypes.func,
};

export default function StoreTableToolbar({ onFilterName, onAddStore }) {
  const debouncedSearch = _.debounce((event) => onFilterName(event.target.value), 300);
  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5 }}>
      <Stack direction="row" spacing={2} flex={1}>
        <TextField
          fullWidth
          onChange={debouncedSearch}
          placeholder="Rechercher..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Button
        variant="contained"
        color="primary"
        onClick={onAddStore}
        startIcon={<Iconify icon="eva:plus-fill" />}
        size="small"
        data-testid="new-store-button"
      >
        Nouveau Magasin
      </Button>
      <StoreForm />
    </Stack>
  );
}
