import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  TextField,
  FormControl,
  Stack,
  IconButton,
} from '@mui/material';
import ApiService from 'src/services/api';
import { LoadingButton } from '@mui/lab';
import useDialogs from 'src/hooks/useDialogs';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import SubpageAppBar from 'src/components/ftp/SubpageAppBar';
import { BackendError } from 'src/services/BackendError';

// App
export default function NewMachine() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { id } = useParams();
  const [formData, setFormData] = useState({
    assignedStore: id,
  });
  const { alert } = useDialogs();

  // Constantly check if machine id field is updated
  useEffect(() => {
    // Reset the error so it doesn't confuse the user
    setError('');
  }, [formData]);

  const handleSubmission = async () => {
    // Set loading state
    setLoading(true);

    try {
      // Submit data
      const response = await ApiService.addMachineToStore({
        machineId: formData.machineIdentifier,
        storeId: formData.assignedStore,
      });

      navigate(`/stores/${id}`);
    } catch (error) {
      if (error instanceof BackendError) {
        if (error.conflict) {
          setError("La machine est déjà enregistrée");
        } else if (error.badParameter) {
          setError("Invalid Machine Identifier");
        } else {
          setError("Something went wrong!");
        }
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* App bar */}
      <SubpageAppBar pageName="Enregistrer une machine" showBackButton />

      {/* Page Content */}
      <Page title="Enregistrer une machine">
        <Container maxWidth={false}>
          <HeaderBreadcrumbs
            heading="Enregistrer une machine"
            links={[{ name: `Enregistrer une machine au magasin` }]}
          />

          {/* Form Steps */}
          <form>
            <Stack spacing={3}>
              {/* Machine Identifier Input */}
              <FormControl fullWidth>
                <TextField
                  error={error !== ''}
                  helperText={error}
                  autoFocus
                  fullWidth
                  label="Identifier"
                  type="text"
                  placeholder={'xxxx-xxxx-xxxx'}
                  inputProps={{
                    "data-testid": "machine-identifier"
                  }}                  
                  value={formData?.machineIdentifier || ''}
                  onChange={(e) => setFormData((prev) => ({ ...prev, machineIdentifier: e.target.value }))}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <IconButton
                          onClick={() =>
                            alert({
                              title: "Trouver l'identifiant de la machine",
                              message:
                                "Cet identifiant unique est une petite clé située sur le serveur physique, généralement sur un autocollant ou une étiquette attachée à l'arrière ou au bas de l'unité. Il est inclus dans la livraison à votre client et sert de référence pour configurer correctement le système en vue de son utilisation.",
                            })
                          }
                          aria-label="Need help finding machine identifier?"
                        >
                          <Iconify
                            color={theme.palette.grey[800]}
                            style={{ margin: 0, padding: 0 }}
                            icon="material-symbols:help"
                            width={22}
                            height={22}
                          />
                        </IconButton>
                      ),
                    },
                  }}
                />
              </FormControl>

              {/* "Select which store to use" dropdown */}
              <AssignedStoreSelection form={{ data: formData, setData: setFormData }} />

              {/* Submit Button */}
              <Stack display={'flex'} flexDirection="row" gap={1} justifyContent={{ xs: 'center', sm: 'end' }}>
                <LoadingButton
                  loading={loading}
                  onClick={handleSubmission}
                  loadingPosition="start"
                  startIcon={<Iconify icon="fluent:tab-add-20-filled" color="#fff" width={20} height={20} />}
                  variant="contained"
                >
                  Enregistrer
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Container>
      </Page>
    </>
  );
}

function AssignedStoreSelection({ form }) {
  const [store, setStore] = useState(null);

  // Fetch a list of stores and store them in a state
  useEffect(() => {
    const getStores = async () => {
      const stores = await ApiService.fetchStores();
      const store = stores.find((s) => s.id === form.data.assignedStore);
      setStore(store);
    }
    getStores();
  }, []);

  return (
    <FormControl fullWidth>
      <TextField
        disabled
        label="La machine sera assignée au magasin"
        value={store?.name || ''}
      />
    </FormControl>
  );
}
