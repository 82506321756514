import { useNavigate } from 'react-router';
import { Box, Typography, Card, Stack, useTheme, Tooltip, IconButton } from '@mui/material';

// API Service
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import SlicedText from 'src/components/ftp/SlicedString';
import EmptyStateSkeleton from '../../../../components/ftp/EmptyStateSkeleton';

export default function Machines({ machines }) {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative' }}>
      {/* If there are no machines */}
      {machines.length === 0 ? (
        <EmptyStateSkeleton
          title="Pas de machines"
          text="Créer une machine pour commencer à ajouter des caméras"
          buttonText="Ajouter une machine"
          buttonOnClick={() => navigate('new-machine')}
          dataTestId="add-machine-button"
        />
      ) : (
        <Box
          sx={{
            gap: (theme) => theme.spacing(2),
            paddingRight: (theme) => theme.spacing(2),
            display: 'flex',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            scrollbarWidth: 'none', // Hide scrollbar for Firefox
            '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome
          }}
        >
          {/* There are machines, iterate over all of them */}
          {machines.map((machine) => (
            <Card key={machine.machineId} sx={{ p: 1, minWidth: 'fit-content' }}>
              <Stack direction="row">
                {/* Icon Box */}
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: 42, height: 42, borderRadius: 4, bgcolor: (theme) => theme.palette.action.selected }}
                >
                  <Iconify icon="bi:server" color="#fff" width={24} height={24} />
                </Stack>

                {/* Label */}
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  sx={{ pointerEvents: 'none', ml: 1, textAlign: 'left' }}
                >
                  {/* Machine text */}
                  <Typography variant="body2" sx={{ mt: '-2px', color: 'text.disabled' }}>
                    Machine
                  </Typography>

                  <Stack direction="row" justifyContent="space-between">
                    {/* Machine Identifier (AKA Hash) */}
                    <Label variant="ghost" sx={{ textTransform: 'uppercase' }}>
                      <SlicedText text={machine.machineId} />
                    </Label>

                    {machine.status === 'online' && (
                      <Label variant="ghost" color="success" bgcolor="#e3f9e0" sx={{ ml: 1, gap: 0.5 }}>
                        <StatusIndicator color="#229b15c9" status="online" />
                      </Label>
                    )}

                    {machine.status === 'inactive' && (
                      <Label variant="ghost" color="warning" bgcolor="#e3f9e0" sx={{ ml: 1, gap: 0.5 }}>
                        <StatusIndicator color="#b78105" status="inactive" />
                      </Label>
                    )}

                    {machine.status === 'offline' && (
                      <Label variant="ghost" color="error" bgcolor="#ffe3e1" sx={{ ml: 1, gap: 0.5 }}>
                        <StatusIndicator color="#b72136" status="offline" />
                      </Label>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          ))}

          <Box sx={{ width: '60px', height: '60px' }}>
            <Card
              sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Tooltip title="Ajouter une nouvelle machine Alfred">
                <IconButton onClick={() => navigate(`new-machine`)} data-testid="add-machine-button">
                  <Iconify icon="mingcute:add-fill" color={theme.palette.grey[500]} width={26} height={26} />
                </IconButton>
              </Tooltip>
            </Card>
          </Box>

          {/* Right fade gradient (items fading in) */}
          <SideGradient side="right" />
        </Box>
      )}
    </Box>
  );
}

function SideGradient({ side }) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        [side]: 0,
        width: (theme) => theme.spacing(2),
        height: '100%',
        background: (theme) => `linear-gradient(to left, ${theme.palette.background.default}, transparent)`,
        // bgcolor: "orange",
        pointerEvents: 'none', // So it doesn't block the scroll
      }}
    />
  );
}

function StatusIndicator({ color, status }) {
  return (
    <>
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: color,
          display: 'inline-block',
        }}
      />
      {status}
    </>
  );
}
