import jwtDecode from 'jwt-decode';
//
import axios from './axios';

const isValidToken = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const TOKEN_LOCALSTORAGE_KEY = 'token'

function getBackendToken() {
  return localStorage.getItem(TOKEN_LOCALSTORAGE_KEY)
}

const setSession = (token) => {
  if (token) {
    localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    console.log('Remove');
    localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, getBackendToken };
