import { Skeleton } from '@mui/material';

// Views
import Machines from './Machines';
import Cameras from './Cameras';

/**
 * Primary overview of store details page
 *
 * @param {Object} props
 * @param {Object} props.storeDetails - Store details object from fetch request
 * @param {Boolean} props.isLoading - True if the parent is currently loading API data
 */
export default function Overview({ storeDetails, isLoading }) {
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton variant="rounded" height="60px" sx={{ mb: 2 }} />
          <Skeleton variant="rounded" height="413px" />
        </>
      ) : (
        <>
          <Machines machines={storeDetails.machines || []} />
          <Cameras cameras={storeDetails.cameras || []} />
        </>
      )}
    </>
  );
}
