import { useEffect, useState } from 'react';
import {
    Tab,
    Tabs,
    Card,
    Table,
    Divider,
    TableBody,
    Container,
    TableContainer,
    Stack,
    Button,
    Typography,
    Box
} from '@mui/material';
import useTabs from '../../hooks/useTabs';
import useTable from '../../hooks/useTable';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableHeadCustom, TableNoData } from '../../components/table';
import { useSafeLazyQuery, useSafeMutation } from '../../services/apollo-client/wrappers';
import { LIST_STORES } from '../../graphql/stores/queries';
import { StoreTableRow, StoreTableToolbar } from '../../sections/@dashboard/store/list';
import StoreForm from '../../sections/@dashboard/store/form/StoreForm';
import { DELETE_STORE, UPDATE_STORE } from '../../graphql/stores/mutations';
import useDialogs from '../../hooks/useDialogs';

const STATUS_OPTIONS = [
    {
        value: 'active',
        label: 'Actifs',
    },
    {
        value: 'inactive',
        label: 'Inactifs',
    },
];

const TABLE_HEAD = [
    { id: 'id', label: 'Identifiant', align: 'left' },
    { id: 'code', label: 'Code Magasin', align: 'left' },
    { id: 'name', label: 'Nom', align: 'left' },
    { id: 'hasValidation', label: "Mode d'envoi", align: 'left' },
    { id: 'createdAt', label: 'Enregistré le', align: 'left' },
    { id: '' },
];

export default function StoresList() {
    const { page, setPage } = useTable();
    const { confirm } = useDialogs();

    const [selectedStore, setSelectedStore] = useState(null);
    const [stores, setStores] = useState([]);
    const [search, setSearch] = useState('');
    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('active');

    const [listStores, { data, loading }] = useSafeLazyQuery(LIST_STORES, {
        fetchPolicy: 'network-only',
        onCompleted: ({ listStores }) => {
            setStores((s) => [...s, ...listStores.stores]);
            setPage((p) => p + 1);
        },
    });

    const [saveStore] = useSafeMutation(UPDATE_STORE, {
        refetchQueries: ['listStores'],
    });

    const [deleteStore] = useSafeMutation(DELETE_STORE, {
        refetchQueries: ['listStores'],
    });

    useEffect(() => {
        listStores({
            onCompleted: ({ listStores }) => {
                setStores(listStores.stores);
                setPage(2);
            },
            variables: {
                pagination: {
                    page: 1,
                },
                filters: {
                    inactive: filterStatus === 'inactive',
                    search,
                },
            },
        });
    }, [listStores, filterStatus, search, setPage]);

    const hasMore = data?.listStores?.hasMore;
    const isNotFound = !loading && ((!stores.length && !!search) || (!stores.length && !!filterStatus && !loading));

    const onAddStore = () => setSelectedStore({});
    const onEditStore = (store) => setSelectedStore(store);
    const onCloseDialog = () => setSelectedStore(null);

    const handleLoadMore = async () => {
        try {
            await listStores({
                variables: {
                    pagination: {
                        page,
                    },
                    filters: {
                        inactive: filterStatus === 'inactive',
                        search,
                    },
                },
            });
        } catch {
            // Nothing to do
        }
    };

    const handleSaveStore = async (input) => {
        try {
            await saveStore({
                variables: {
                    input,
                },
            });
        } catch {
            // Nothing to do
        }
    };

    const handleDeleteStore = async (id) => {
        try {
            if (
                await confirm({
                    title: "Suppression d'un magasin",
                    message: 'Cette action est irréversbile. Souhaitez-vous continuer ?',
                })
            )
                await deleteStore({
                    variables: {
                        id,
                    },
                });
        } catch {
            // Nothing to do
        }
    };

    return (
        <Page title="Admin">
            {selectedStore && <StoreForm store={selectedStore} onClose={onCloseDialog} />}
            <Container maxWidth={false}>
                <HeaderBreadcrumbs heading="Admin" links={[{ name: "Administration des magasins" }]} />

                <Card sx={{ p: 4 }}>
                    {/* Header */}
                    <Box>
                        <Typography variant="h4">Les magasins</Typography>
                        <Typography sx={{ color: "text.disabled" }}>Administration de tout les magasins</Typography>
                    </Box>

                    {/* Active / Inactive Tabs */}
                    <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons="auto"
                        value={filterStatus}
                        onChange={onChangeFilterStatus}
                        sx={{
                            my: 2,
                        }}
                    >
                        {STATUS_OPTIONS.map(({ label, value }) => (
                            <Tab disableRipple key={value} label={label} value={value} />
                        ))}
                    </Tabs>

                    <Divider />

                    <StoreTableToolbar onFilterName={setSearch} onAddStore={onAddStore} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                            <Table size="small">
                                <TableHeadCustom headLabel={TABLE_HEAD} rowCount={stores.length} />

                                <TableBody>
                                    {stores.map((row) => (
                                        <StoreTableRow
                                            key={row.id}
                                            store={row}
                                            onSaveStore={handleSaveStore}
                                            onEditStore={() => {
                                                onEditStore(row);
                                            }}
                                            onDeleteStore={() => {
                                                handleDeleteStore(row.id);
                                            }}
                                        />
                                    ))}

                                    <TableNoData isNotFound={isNotFound} />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        py={2}
                        sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}` }}
                    >
                        {hasMore && (
                            <Button variant="contained" size="small" onClick={handleLoadMore}>
                                Voir plus de résultats ({data?.listStores?.total - stores.length})
                            </Button>
                        )}
                        {!hasMore && <Typography variant="caption">Tous les résultats sont affichés</Typography>}
                    </Stack>
                </Card>
            </Container>
        </Page>
    );
}
