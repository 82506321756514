import {
    Card,
    Typography,
    Button
} from "@mui/material";

/**
 * A full-width card used to tell the user that something hasn't been set up yet.
 * 
 * @param {object} props
 * @param {string} props.title - Card title
 * @param {string} props.text - Card text
 * @param {string} props.buttonText - Button text
 * @param {function} props.buttonOnClick - Function to call when button is clicked
 * @param {boolean} props.disabled - Disable the component (50% opacity & button disabled)
 * 
 * @example
 * <EmptyStateSkeleton
 *  title="No machines!"
 *  text="Add a machine now to start adding cameras to your account."
 *  buttonText="Add Machine"
 *  buttonOnClick={() => alert("You clicked me!")}
 * />
 */
export default function EmptyStateSkeleton({ title, text, buttonText, buttonOnClick, dataTestId, disabled=false }) {
    return (
        <Card sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", py: 10, opacity: (disabled ? 0.5 : 1) }}>
            <Typography variant="h3">{title}</Typography>
            <Typography sx={{ mt: 1, width: { xs: "80%", sm: "100%" }, textAlign: "center" }} variant="body2">{text}</Typography>
            <Button onClick={buttonOnClick} sx={{ mt: 2 }} variant="text" disabled={disabled} data-testid={dataTestId}>{buttonText}</Button>
        </Card>
    );
}