import { gql } from '@apollo/client';

const SAVE_DEVICE = gql`
  mutation saveDevice($input: SaveDeviceInput!) {
    saveDevice(input: $input) {
      id
      identifier
      name
      type
      authorizedAt
      inactive
      isMuted
    }
  }
`;

export { SAVE_DEVICE };
