import { Card, Button, Typography, Stack } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { QRCodeSVG } from 'qrcode.react';
import useResponsive from 'src/hooks/useResponsive';

const InviteCollaborators = ({ onOpen, fullWidth }) => {
  const { stores } = useAuth();
  const isDesktop = useResponsive('up', 'lg');
  const onlyOneStore = stores && stores.length === 1 ? stores[0] : null
  const hasMultipleStores = stores && stores.length > 1;

  return (
    <Card
      sx={{
        p: 2,
        mb: 6,
        borderRadius: '8px',
        border: 'none',
        width: isDesktop && !fullWidth ? '50%' : '100%',
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <Stack
        direction={onlyOneStore ? 'row' : 'column'}
        spacing={2}
        alignItems={!isDesktop ? 'center' : 'flex-start'}
      >
        <Stack flex={1}>
          <Typography variant="h6">Inviter des collaborateurs</Typography>
          <Typography variant="body">
            QR code à faire scanner auprès de vos collaborateurs pour les inviter sur un magasin de votre choix
          </Typography>
        </Stack>
        {onlyOneStore && (
          <Stack sx={{ cursor: 'pointer' }} alignItems="center" onClick={onOpen}>
            <QRCodeSVG value={onlyOneStore.invitationUrl} size={85} />
          </Stack>
        )}
        {hasMultipleStores && (
          <Button variant="contained" fullWidth security="large" onClick={onOpen}>
            Inviter mes collaborateurs
          </Button>
        )}
      </Stack>
    </Card>
  );
};

export default InviteCollaborators;
