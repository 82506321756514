import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { MenuItem, Stack } from '@mui/material';
import CollaboratorsDialog from 'src/sections/profile/collaborators/CollaboratorsDialog';
import QuestionsAnswersDialog from 'src/sections/profile/help-support/QuestionsAnswersDialog';
import AccordionItem from 'src/sections/profile/AccordionItem';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import MyAvatar from 'src/components/MyAvatar';
import MenuPopover from 'src/components/MenuPopover';
import { IconButtonAnimate } from 'src/components/animate';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import USER_ROLES from 'src/constants/userRoles';

export default function AccountPopover() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { ACTIONS_HANDLERS } = useAlertsFilters();

  const { logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleProfilePageOpen = () => {
    navigate(PATH_DASHBOARD.profile);
    handleClose();
  };

  const handleNavigateToMyFavorites = () => {
    ACTIONS_HANDLERS.resetAllFilters();
    ACTIONS_HANDLERS.setFavorite(true);
    navigate(PATH_DASHBOARD.alerts.list);
  };

  const MENU_ITEMS = [
    {
      label: 'Voir mon profil',
      imageUrl: '/assets/profile/my-profile.svg',
      action: handleProfilePageOpen,
      Component: null,
      roles: Object.values(USER_ROLES),
    },
    {
      label: 'Mes collaborateurs',
      imageUrl: '/assets/profile/my-workers.svg',
      Component: CollaboratorsDialog,
      action: null,
      roles: [USER_ROLES.STORE, USER_ROLES.EMPLOYEE],
    },
    {
      label: 'Mes favoris',
      imageUrl: '/assets/profile/my-favorites.svg',
      Component: null,
      action: handleNavigateToMyFavorites,
      roles: [USER_ROLES.STORE, USER_ROLES.EMPLOYEE],
    },
    {
      label: 'Aide & support',
      imageUrl: '/assets/profile/help-support.svg',
      Component: QuestionsAnswersDialog,
      action: null,
      roles: Object.values(USER_ROLES),
    },
    {
      label: 'Déconnexion',
      imageUrl: '/assets/profile/logout.svg',
      Component: null,
      action: handleLogout,
      roles: Object.values(USER_ROLES),
    },
  ];

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
        data-testid="account-avatar-button"
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          {MENU_ITEMS.map((accordion) => {
            const shouldDisplay = accordion.roles.includes(user.role);

            if (!shouldDisplay) return null;

            return (
              <MenuItem
                key={accordion.label}
                sx={{
                  p: 1,
                  maxHeight: 32,
                  '&:hover': {
                    cursor: 'pointer',
                    color: 'primary.main',
                    backgroundColor: '#071ac814 !important',
                    borderRadius: (theme) => theme.spacing(1),
                  },
                }}
              >
                <AccordionItem key={accordion.label} accordion={accordion} embedInMenu handleCloseMenu={handleClose} />
              </MenuItem>
            );
          })}
        </Stack>
      </MenuPopover>
    </>
  );
}
