import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  TextField,
  Stack,
  InputLabel,
  Button,
} from '@mui/material';
import ApiService from 'src/services/api';
import { useTheme } from '@mui/material/styles';
import Iconify from 'src/components/Iconify';

import CardToggleButton from 'src/components/ftp/CardToggleButton';
import SlicedText from 'src/components/ftp/SlicedString';
import TabContainer from '../../../../../components/ftp/TabContainer';

// App
export default function Machine({ form }) {
  const theme = useTheme();
  const [machines, setMachines] = useState([]);

  // Fetch machines from API
  useEffect(() => {
    const getMachines = async () => {
      const machinesData = (await ApiService.fetchStore(form.data.assignedStore)).machines;

      // Update state
      setMachines(machinesData);

      // If there's only one machine in the list, set the form data to this one by default
      if (machinesData.length === 1) {
        form.setData((prev) => ({ ...prev, machine: machinesData[0].id }));
      }
    };
    getMachines();
  }, []);

  return (
    <TabContainer>
      <ToggleButtonGroup
        sx={{ display: 'flex', gap: 3, bgcolor: 'transparent', border: 'none' }}
        value={form.data?.machine}
        exclusive
        onChange={(e) => form.setData((prev) => ({ ...prev, machine: e.currentTarget.value }))}
        fullWidth
      >
        {/* TODO: Use "status" indicator to give users more information! */}
        {machines.map(({ machineId }) => (
          <CardToggleButton
            key={machineId}
            value={machineId}
            icon={
              <Iconify
                icon="bi:server"
                color={form.data?.machine === machineId ? theme.palette.primary.main : theme.palette.grey[500]}
                width={26}
                height={26}
              />
            }
            label={<SlicedText text={machineId} />}
          />
        ))}
      </ToggleButtonGroup>
    </TabContainer>
  );
}
