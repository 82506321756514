import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import { RHFCheckbox, RHFTextField } from '../../../../components/hook-form';
import FormDialog from '../../../../components/form/FormDialog';
import { useSafeMutation } from '../../../../services/apollo-client/wrappers';
import { CREATE_STORE, UPDATE_STORE } from '../../../../graphql/stores/mutations';

const StoreForm = ({ store, onClose }) => {
  const edit = Boolean(store?.id);

  const [upsertStore, { loading }] = useSafeMutation(edit ? UPDATE_STORE : CREATE_STORE, {
    refetchQueries: ['listStores'],
  });

  const StoreSchema = Yup.object().shape({
    code: Yup.string().required('Ce champ est requis'),
    name: Yup.string().required('Ce champ est requis'),
    hasValidation: Yup.boolean(),
  });

  const defaultValues = {
    code: store?.code,
    name: store?.name,
    hasValidation: store?.hasValidation,
  };

  const methods = useForm({
    resolver: yupResolver(StoreSchema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      await upsertStore({
        variables: {
          input: {
            id: store?.id,
            ...data,
          },
        },
      });
    } catch (error) {
      //
    }
  };
  return (
    <FormDialog
      edit={edit}
      loading={loading}
      open={Boolean(store)}
      methods={methods}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Stack spacing={2}>
        <RHFTextField
          autoFocus
          name="code"
          placeholder="Code magasin"
          helperText="Ne doit pas comporter d'espace ou de caractères spéciaux"
        />
        <RHFTextField name="name" placeholder="Nom du magasin" />
        <Stack>
          <RHFCheckbox name="hasValidation" label="Pré-validation des alertes émises" />
          <Typography variant="caption">
            Si cette option est cochée, les alertes ne seront envoyées qu'après validation de celles-ci par un
            administrateur.
          </Typography>
        </Stack>
      </Stack>
    </FormDialog>
  );
};

export default StoreForm;
