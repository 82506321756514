import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import configs from 'src/config/index';
import {
  Container,
  Typography,
  Skeleton,
  IconButton,
} from '@mui/material';

import ApiService from 'src/services/api';

// Icons
import { IosShare } from '@mui/icons-material';

// Custom
import SubpageAppBar from 'src/components/ftp/SubpageAppBar';

import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import EditIcon from '@mui/icons-material/Edit';

import Overview from './overview';

export default function StoreDetails() {
  // Get the store ID from the URL parameters
  const { id } = useParams();
  const [storeDetails, setStoreDetails] = useState({});
  const [loading, setLoading] = useState(false);

  // Fetch API data
  useEffect(() => {
    const getStoreDetails = async () => {
      setLoading(true);
      try {
        const store = await ApiService.fetchStore(id);
        setStoreDetails(store);
      } finally {
        setLoading(false);
      }
    };
    getStoreDetails();
  }, [id]);

  // console.log("Store details =", storeDetails);

  return (
    <>
      {/* App Bar */}
      <SubpageAppBar pageName="Store Details" showBackButton>
        {configs.features.appBarButtons && (
          <>
            <IconButton
              onClick={() => alert('Edit button')}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <EditIcon />
            </IconButton>

            <IconButton onClick={() => alert('Share button')} size="large" edge="start" color="inherit" aria-label="menu">
              <IosShare />
            </IconButton>
          </>
        )}
      </SubpageAppBar>

      {/* Page Content */}
      <Page title={storeDetails.name || "Untitled Store"}>
        <Container maxWidth={false}>
          {loading ? (
            <>
              <Skeleton variant="rounded" width="122px" height="22px" sx={{ mb: 1 }} />
              <Skeleton variant="rounded" width="400px" height="53px" sx={{ mb: 4 }} />
            </>
          ) : (
            <>
              <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                Vue d'ensemble de
              </Typography>
              <HeaderBreadcrumbs heading={storeDetails.name || "Untitled Store"} sx={{ mb: 4 }} />
            </>
          )}

          {/* Page Overview */}
          <Overview storeDetails={storeDetails} isLoading={loading} />
          
        </Container>
      </Page>
    </>
  );
}
