class BackendError extends Error {
  constructor(statusCode) {
    super(`BackendError - ${statusCode}`);

    this.statusCode = statusCode;
    this.badParameter = Math.floor(statusCode / 100) === 4;
    this.conflict = (statusCode === 409);
  }
};

export { BackendError };