import configs from 'src/config/index';
import { getBackendToken } from 'src/utils/jwt';

import { BackendError } from "./BackendError";

const adaptCamera = (dto) => ({
  name: dto.name,
  stream: dto.stream,
  storeId: dto.store_id,
  machineId: dto.machine_id,
  cameraId: dto.camera_id,
  cameraType: dto.camera_type,
  createdAt: new Date(dto.created_at),
  updatedAt: new Date(dto.updated_at)
})

const adaptMachine = (dto) => ({
  machineId: dto.machine_id,
  storeId: dto.store_id,
  createdAt: new Date(dto.created_at),
  updatedAt: new Date(dto.updated_at)
})

/**
 * A SINGULAR class to handle API requests for all app services.
 */
class ApiServiceClass {
  static instance = null;

  /**
   * Creates an instance of ApiServiceClass.
   * If an instance already exists, the same instance is returned.
   *
   * @param {string} baseURL - The base URL for all API requests.
   * @returns {ApiServiceClass} The instance of the ApiServiceClass class.
   */
  constructor(baseURL) {
    // Check if this instance already exists
    if (!ApiServiceClass.instance) {
      this.baseURL = baseURL;
      ApiServiceClass.instance = this;
    }
  }

  /**
   * Get the singleton instance of the ApiServiceClass.
   *
   * @param {string} [baseURL] - Optional base URL for the instance.
   * @returns {ApiServiceClass} The instance of the ApiServiceClass.
   */
  static getInstance(baseURL) {
    if (!ApiServiceClass.instance) {
      ApiServiceClass.instance = new ApiServiceClass(baseURL);
    }
    return ApiServiceClass.instance;
  }

  // Utility methods
  async fetchData(endpoint, fetchOptions) {
    const response = await fetch(`${this.baseURL}/${endpoint}`, {
      ...fetchOptions,
      headers: {
        ...(fetchOptions || {}).headers,
        Authorization: `Bearer ${getBackendToken()}`,
      },
    });
    if (!response.ok) {
      throw new BackendError(response.status);
    }
    return response.json();
  }

  async post(endpoint, body) {
    return this.fetchData(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
  }

  async postData(endpoint, data) {
    return this.fetchData(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }

  async patch(endpoint, data) {
    return this.fetchData(endpoint, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }

  async deleteData(endpoint) {
    return this.fetchData(endpoint, {
      method: 'DELETE',
    });
  }

  /**
   * Fetch a list of stores
   *
   * @returns {Promise<Object[]>} A promise of a list of stores
   */
  async fetchStores() {
    const adaptStore = (dto) => ({
      id: dto.store_id,
      name: dto.name,
      createdAt: new Date(dto.created_at),
      updatedAt: new Date(dto.updated_at),
    });

    const storesResponse = await this.fetchData('stores');
    return storesResponse.stores.map(adaptStore);
  }

  /**
   * Fetch a singular store
   *
   * @param {string} storeId - ID of store
   * @returns {Promise<Object[]>} A promise of store information
   */
  async fetchStore(storeId) {
    if (!storeId) {
      throw Error('fetchStore: storeId is empty');
    }

    const adaptStoreDetails = (dto) => ({
      id: dto.store_id,
      code: dto.code,
      name: dto.name,
      inactive: dto.inactive,
      createdAt: new Date(dto.created_at),
      updatedAt: new Date(dto.updated_at),
      cameras: dto.cameras.map(adaptCamera),
      machines: dto.alfred_machines.map(adaptMachine),
    });

    return adaptStoreDetails(await this.fetchData(`store/${storeId}`));
  }

  /**
   * Fetch a list of cameras associated with a store
   *
   * @param {string} storeId - ID of store
   * @returns {Promise<Object[]>} A promise of a list of cameras
   */
  async fetchCameras(storeId) {
    return this.fetchData(`store/${storeId}/cameras`);
  }

  /**
   * Assign a machine to a store
   */
  async addMachineToStore({ storeId, machineId }) {
    return this.post('alfred-machine', {
      store_id: storeId,
      machine_id: machineId,
    });
  }

  /**
   * Create & add a machine to the database
   *
   * @param {string} storeId
   * @param {Object[]} cameraData
   * @returns {Promise<Object[]>} A promise of the response
   */
  async addCamera(storeId) {
    return adaptCamera(
      await this.postData('camera', {
        store_id: storeId,
      })
    );
  }

  async patchCamera({ cameraId, machineId, name, cameraType, streamUrl }) {
    const adaptCameraTypeDto = (cameraType) => {
      switch (cameraType) {
        case 'ip':
          return 'IP';
        case 'dvr':
          return 'DRV';
        case null:
          return 'UNDEFINED';
        default:
          return undefined;
      }
    };

    const camera = await this.patch(`camera/${encodeURIComponent(cameraId)}`, {
      name,
      stream: streamUrl.toString(),
      camera_type: adaptCameraTypeDto(cameraType),
      machine_id: machineId
    });

    return adaptCamera(camera);
  }
}

// Create the API service with the AlfredEyes base API URL
const ApiService = ApiServiceClass.getInstance(configs.alfredAPI.base);

// Export the service class
export default ApiService;
