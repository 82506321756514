
import Fuse from "fuse.js";

/**
 * A fun function which returns an Iconify icon based on a string
 * It uses Fuse.js to find links between different works even if
 * there aren't any direct matches.
 * 
 * @param {string} title 
 * @returns {string}
 * 
 * @example
 * const icon = getIcon("A snazzy purple turtle cutting a baguette");
 * console.log(icon); // "mdi:bread" or "mdi:knife"
 */
export default function getIcon(title) {
  
  // Define a mapping of keywords to icon names
  const iconKeywords = [
    { keyword: "camera", icon: "mdi:camera" },
    { keyword: "door", icon: "mdi:door" },
    { keyword: "bakery", icon: "mdi:baguette" },
    { keyword: "food", icon: "mdi:silverware-fork-knife" },
    { keyword: "store", icon: "mdi:store" },
    { keyword: "checkout", icon: "mdi:cash-register" },
    { keyword: "entrance", icon: "mdi:door-open" },
    { keyword: "exit", icon: "mdi:exit-run" },
    { keyword: "produce", icon: "mdi:fruit-watermelon" },
    { keyword: "dairy", icon: "mdi:milk" },
    { keyword: "frozen", icon: "mdi:snowflake" },
    { keyword: "aisle", icon: "mdi:storefront-outline" },
    { keyword: "carrefour", icon: "mdi:shopping" },
    { keyword: "clothing", icon: "mdi:tshirt-crew-outline" },
    { keyword: "cashier", icon: "mdi:cash-multiple" },
    { keyword: "pharmacy", icon: "mdi:pharmacy" },
    { keyword: "health", icon: "mdi:heart-pulse" },
    { keyword: "beauty", icon: "mdi:lipstick" },
    { keyword: "cosmetics", icon: "mdi:makeup" },
    { keyword: "meat", icon: "mdi:food-steak" },
    { keyword: "seafood", icon: "mdi:fish" },
    { keyword: "deli", icon: "mdi:food-drumstick" },
    { keyword: "bakery", icon: "mdi:baguette" },
    { keyword: "toys", icon: "mdi:toy-brick-outline" },
    { keyword: "electronics", icon: "mdi:cellphone-link" },
    { keyword: "hardware", icon: "mdi:tools" },
    { keyword: "garden", icon: "mdi:flower" },
    { keyword: "floral", icon: "mdi:flower-tulip" },
    { keyword: "home", icon: "mdi:home" },
    { keyword: "kitchen", icon: "mdi:silverware-fork-knife" },
    { keyword: "checkout", icon: "mdi:credit-card-scan" },
    { keyword: "furniture", icon: "mdi:sofa-outline" },
    { keyword: "wine", icon: "mdi:glass-wine" },
    { keyword: "beer", icon: "mdi:beer-outline" },
    { keyword: "spirits", icon: "mdi:glass-cocktail" },
    { keyword: "storage", icon: "mdi:warehouse" },
    { keyword: "stockroom", icon: "mdi:package-variant-closed" },
    { keyword: "office", icon: "mdi:office-building" },
    { keyword: "staff", icon: "mdi:account-group-outline" },
    { keyword: "restroom", icon: "mdi:human-male-female" },
    { keyword: "security", icon: "mdi:shield-lock-outline" },
    { keyword: "parking", icon: "mdi:parking" },
    { keyword: "entry", icon: "mdi:gate" },
    { keyword: "garage", icon: "mdi:garage" },
    { keyword: "delivery", icon: "mdi:truck-delivery" },
    { keyword: "loading", icon: "mdi:forklift" },
    { keyword: "cash", icon: "mdi:cash" },
    { keyword: "self-checkout", icon: "mdi:tablet-cellphone" },
    { keyword: "seasonal", icon: "mdi:calendar" },
    { keyword: "grocery", icon: "mdi:shopping-outline" },
    { keyword: "bistro", icon: "mdi:silverware" },
    { keyword: "customer service", icon: "mdi:account-question" },
    // Add more keywords and icons here! Just ask ChatGPT!
  ];  

  // Configure Fuse for fuzzy searching
  const fuse = new Fuse(iconKeywords, {
    keys: ["keyword"],
    threshold: 0.8, // Lower this value to make matching less strict
    distance: 100 // Allows matches even if the term isn't exact
    // minMatchCharLength: 2, // Minimum length of the matched characters
  });

  // Search for the best match in the title
  const result = fuse.search(title);

  // Return the icon of the best match, or a default if no match is found
  return result.length > 0 ? result[0].item.icon : "mdi:shopping";
};
