import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  Typography,
  Card,
  Grid2,
  Stack,
  IconButton,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';

// Table
import Iconify from 'src/components/Iconify';
import EmptyStateSkeleton from '../../../../components/ftp/EmptyStateSkeleton';

export default function Cameras({ cameras }) {
  const navigate = useNavigate();

  return (
    <Box sx={{ mt: 2 }}>
      {cameras.length === 0 ? (
        <EmptyStateSkeleton
          title="Aucune caméra attribuée"
          text="Ajoutez des caméras pour compléter votre magasin"
          buttonText="Ajouter une caméra"
          buttonOnClick={() => navigate('new-camera')}
          dataTestId="add-camera-button"
        />
      ) : (
        <Card sx={{ mt: 2, p: 2 }}>
          {/* SearchBar */}
          <Stack direction="row" spacing={2}>
            {/* Header */}
            <Box sx={{ width: '100%' }}>
              <Typography variant="h4">Cameras</Typography>
              <Typography sx={{ color: 'text.disabled' }}>Cameras associated with this shop</Typography>
            </Box>

            <Button
              sx={{ width: 200 }}
              variant="contained"
              color="primary"
              onClick={() => navigate('new-camera')}
              startIcon={<Iconify icon="eva:plus-fill" />}
              size="small"
              data-testid="add-camera-button"
              >
              Add Camera
            </Button>
          </Stack>

          {/* List of cameras */}
          <Box sx={{ mt: 2 }}>
            <CameraList cameras={cameras} />
          </Box>
        </Card>
      )}
    </Box>
  );
}

/*
  These are fun labels which can be used as status indicators.
  They look "fancy", so be sure to use them somewhere!
*/
// {row.status === "Running" && <Label variant="ghost" color="success">{row.status}</Label>}
// {row.status === "Paused" && <Label variant="ghost" color="warning">{row.status}</Label>}
// {row.status === "Stopped" && <Label variant="ghost" color="error">{row.status}</Label>}

function CameraList({ cameras }) {
  return (
    <Grid2 container spacing={2}>
      {cameras.map((camera) => {
        const notifications = randomNotificationAmount();

        return (
          <Grid2 key={camera.cameraId} size={{ sx: 12, sm: 6, md: 4 }} sx={{ borderRadius: 4, overflow: 'hidden' }}>
            <ImageListItem key={camera.preview}>
              <img src="https://placehold.co/600x400/0c0c0c/EEE" alt={camera.name} loading="lazy" />
              <ImageListItemBar
                title={camera.name || 'Untitled Camera'}
                subtitle={camera.updatedAt.toLocaleString()}
                actionIcon={
                  <IconButton
                    sx={{ position: 'relative', color: 'rgba(255, 255, 255, 0.54)', mr: 2 }}
                    aria-label={`info about ${camera.name}`}
                  >
                    {notifications !== 0 && (
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          position: 'absolute',
                          top: 4,
                          right: 4,
                          height: (theme) => theme.spacing(2),
                          px: 0.6,
                          width: 'auto',
                          bgcolor: 'tomato',
                          borderRadius: '20px',
                        }}
                      >
                        <span style={{ fontSize: 12, fontWeight: 600 }}>{notifications}</span>
                      </Stack>
                    )}

                    <Iconify icon="mdi:bell" width={22} height={22} />
                  </IconButton>
                }
              />
            </ImageListItem>
          </Grid2>
        );
      })}
    </Grid2>
  );
}

function randomNotificationAmount() {
  // 50% chance to return 0
  if (Math.random() < 0.5) {
    return 0;
  }

  // Generate a random number between 1 and 40 if not 0
  return Math.floor(Math.random() * 15) + 1;
}
