import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Stack,
  Tooltip,
  ToggleButtonGroup,
  Grid2,
  IconButton,
  Link,
  Skeleton,
} from '@mui/material';

// API Service
import ApiService from 'src/services/api';

// Components
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import CardToggleButton from 'src/components/ftp/CardToggleButton';
import getIcon from 'src/utils/getIcon';


// Page Config
const PAGE_CONFIG = {
  title: "Mes Magasins",
  links: [
    { name: `Gérer vos magasins` }
  ]
}

// App
export default function MyStores() {
  const theme = useTheme();
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch API data
  useEffect(() => {
    const getStores = async () => {
      setLoading(true);
      try {
        // Fetch stores
        const storeData = await ApiService.fetchStores();
        setStores(storeData);
      } catch (error) {
        console.error("Couldn't fetch store list - ", error);
      } finally {
        setLoading(false);
      }
    };
    getStores();
  }, []);

  return (
    <Page title={PAGE_CONFIG.title}>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading={PAGE_CONFIG.title} links={PAGE_CONFIG.links} />

        {loading ? (
          <Stack direction="row" spacing={2}>
            {/* Fancy skeletons which act as a grid */}
            <Skeleton variant="rounded" height="122px" width="100%" />
            <Skeleton variant="rounded" height="122px" width="100%" />
            <Skeleton variant="rounded" height="122px" width="100%" sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }} />
            <Skeleton variant="rounded" height="122px" width="100%" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }} />
          </Stack>
        ) : (
          <ToggleButtonGroup sx={{ border: 'none' }} exclusive fullWidth>
            <Grid2 container spacing={2} sx={{ width: '100%' }}>
              {/* Show a list of stores */}
              <StoreList stores={stores} setLoading={setLoading} />

              {/* Add New Store icon */}
              <Grid2 size={{ xs: 6, md: 4, lg: 3 }}>
                <Box
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid rgba(145, 158, 171, 0.12)',
                    height: '122px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {/* <Tooltip title="Ajouter un nouveau magasin">
                    <IconButton>
                      <Iconify icon="mingcute:add-fill" color={theme.palette.grey[500]} width={26} height={26} />
                    </IconButton>
                  </Tooltip> */}
                </Box>
              </Grid2>
            </Grid2>
          </ToggleButtonGroup>
        )}
      </Container>
    </Page>
  );
}

/**
 * A list of stores as Mui Cards
 * 
 * @param {Object} props
 * @param {Object} props.stores - Array of stores from API fetch
 * @returns {React.ReactNode} Rendered component
 * 
 * @example
 * const storeList = await ApiService.fetchStores();
 * 
 * <StoreList stores={storeList} />
 */
const StoreList = ({ stores }) => {
  const theme = useTheme();

  return (
    <>
      {stores.map(({ name, id }) => (
        <Grid2 key={id} sx={{ position: 'relative' }} size={{ xs: 6, md: 4, lg: 3 }}>
          <Link underline="none" href={`/stores/${id}`}>
            <CardToggleButton
              sx={{ width: '100%', height: '100%' }}
              key={name}
              icon={
                <Iconify
                  icon={getIcon(name) || 'mdi:shopping'}
                  color={theme.palette.grey[500]}
                  width={26}
                  height={26}
                />
              }
              label={name}
            />
          </Link>
        </Grid2>
      ))}
    </>
  );
};
