/*
This file is 80% useless.

It was used as an easy way to manage input field placeholders/names/ids.
*/

export const formConfig = {
    name: {
        label: "Camera Name",
        id: "name",
        placeholders: ["Aisle 5", "Front Door", "Cash Register", "Main Entrance", "Front Door"]
    },

    brand: {
        label: "Camera Brand",
        id: "brand",
        list: [
            "Reolink",
            "Netgear",
            "TP-Link",
            "Hikvision",
            "Dahua",
            "Axis",
            "Foscam",
            "Dahua",
            "Samsung",
            "Google Nest Cam",
            "Ring Spotlight Cam",
            "Other"
        ]
    },

    location: {
        label: "Location",
        id: "location",
        list: [
            "Aisle",
            "Bakery",
            "Checkout Area",
            "Entrance",
            "Exit",
            "Parking Lot",
            "Self-Checkout Area",
            "Stockroom",
            "Customer Service Desk",
        ]
    },

    connectionType: {
        id: "connectionType",
        list: [
            { id: "ip", label: "IP Address", icon: "material-symbols:wifi-sharp" },
            { id: "dvr", label: "DVR", icon: "ic:twotone-cable" },
        ]
    },

    ip: {
        label: "IP Address",
        id: "ipAddress",
        placeholder: "192.168.1.100"
    },

    password: {
        label: "Password",
        id: "authPassword",
        placeholder: "Camera Password"
    },

    // License Inputs
    license: {
        start: {
            label: "License Start",
            id: "licenseStart"
        },
        end: {
            label: "License End",
            id: "licenseEnd"
        }
    }
}