import { Collapse, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SAVE_DEVICE } from '../../../../graphql/device/mutations';
import { GET_CURRENT_DEVICE } from '../../../../graphql/device/queries';
import { useSafeMutation, useSafeQuery } from '../../../../services/apollo-client/wrappers';
import getDeviceInformation from '../../../../utils/getDeviceInformation';
import NotConnectedDevice from './NotConnectedDevice';
import Label from '../../../../components/Label';
import { SkeletonDeviceSubscription } from '../../../../components/skeleton';
import DeviceActions from './DeviceActions';
import useDialogs from '../../../../hooks/useDialogs';
import { SEND_TEST_ALERT } from '../../../../graphql/alerts/mutations';

const DeviceSubscription = () => {
  const { alert } = useDialogs();
  const { confirm } = useDialogs();
  const { name, type, identifier } = getDeviceInformation();
  const [isGranted, setGranted] = useState(Notification.permission === 'granted');

  const [saveDevice, { loading }] = useSafeMutation(SAVE_DEVICE, {
    refetchQueries: ['getStore', 'getCurrentDevice'],
  });

  const [sendTestAlert] = useSafeMutation(SEND_TEST_ALERT, {
    refetchQueries: ['getCurrentDevice'],
  });

  const { data, loading: loadingDevice } = useSafeQuery(GET_CURRENT_DEVICE, {
    variables: {
      name,
      type,
      identifier,
    },
  });

  const device = data?.getCurrentDevice;
  const deviceId = device?.id;

  useEffect(() => {
    if (!isGranted && Notification.permission !== 'default' && deviceId) {
      alert({ message: 'Les notifications ont été désactivés. Vérifiez vos paramètres' });
      // saveDevice({
      //   variables: {
      //     input: {
      //       id: deviceId,
      //       inactive: true,
      //     },
      //   },
      // });
    }
  }, [alert, deviceId, isGranted, saveDevice]);

  if (loadingDevice) return <SkeletonDeviceSubscription />;
  if (!data) return null;

  const handleSubscribe = async (subscription) => {
    // Stringify then parse to get an iterable object,
    const sub = JSON.parse(JSON.stringify(subscription));
    await saveDevice({
      variables: {
        input: {
          id: device?.id,
          identifier,
          name,
          type,
          endpoint: sub.endpoint,
          expirationTime: sub.expirationTime || undefined,
          keys: sub.keys,
        },
      },
    });
  };

  const handleToggleMute = async (isMuted) => {
    let ok = true;

    // Wants to mute
    if (isMuted)
      ok = await confirm({
        message: `Mettre en sourdine les alertes sur cet appareil ?`,
      });

    if (ok)
      await saveDevice({
        variables: {
          input: {
            identifier: device?.identifier,
            isMuted,
          },
        },
      });
  };

  const handleSendTestAlert = async () => {
    await sendTestAlert({
      variables: {
        deviceId: device.id,
      },
    });
  };

  if (!device) return <NotConnectedDevice onSubscribe={handleSubscribe} onPermissionChange={setGranted} />;

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>
          <Label variant="ghost">
            {device.name} {device.type}
          </Label>{' '}
          <Label variant="ghost" color={device.inactive ? 'error' : 'success'}>
            {device.inactive ? 'Inactif' : 'Actif'}
          </Label>
        </Typography>
        <Collapse in={Boolean(device)}>
          <DeviceActions
            isMuted={device?.isMuted}
            onToggleMute={() => handleToggleMute(!device?.isMuted)}
            onSendTestAlert={handleSendTestAlert}
          />
        </Collapse>
      </Stack>
      {!isGranted && !device.inactive && (
        <NotConnectedDevice
          loading={loading}
          hasLostPermission
          onSubscribe={handleSubscribe}
          onPermissionChange={setGranted}
        />
      )}
    </Stack>
  );
};

export default DeviceSubscription;
