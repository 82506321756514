/**
 * Parse 
 * 
 * @param {*} rtspUrl 
 * @returns 
 */
export function parseRTSPUrl(rtspUrl) {
  const parsedUrl = URL.parse(rtspUrl);

  if (parsedUrl === null) {
    return false;
  }

  if (parsedUrl.protocol !== "rtsp:") {
    return false;
  }

  return {
    protocol: parsedUrl.protocol,
    username: parsedUrl.username,
    password: parsedUrl.password,
    ip: parsedUrl.hostname,
    port: parsedUrl.port,
    path: `${parsedUrl.pathname}${parsedUrl.search}`,
  };
}
