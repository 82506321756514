import {
    Box,
    Typography,
    IconButton,
    AppBar,
    Toolbar
} from "@mui/material";
import { useNavigate } from "react-router";

// Icons
import BackArrowIcon from "../BackArrow";

/**
 * A simple AppBar for sub-pages
 * 
 * @param {object} props - Component props
 * @param {string} props.pageName - Page name to display
 * @param {boolean} props.showBackButton - Show the back button 
 * @param {React.ReactNode} props.children - Children elements
 * @returns {React.ReactNode} Rendered component
 * 
 * @example
 * <SubpageAppBar showBackButton pageName={"Détails du Magasin"}>
 *   <IconButton>
 *     <EditIcon />
 *   </IconButton>
 * </SubpageAppBar>
 */
export default function SubpageAppBar({ children, pageName, showBackButton = false }) {
    const navigate = useNavigate();

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ bgcolor: "transparent", color: (theme) => theme.palette.grey[800], boxShadow: "none" }}>
                <Toolbar>
                    {/* Back Button */}
                    {showBackButton && (
                        <IconButton
                            onClick={() => navigate(-1)}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            data-testid="back-button"
                        >
                            <BackArrowIcon />
                        </IconButton>
                    )}

                    {/* Primary Text */}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {pageName}
                    </Typography>

                    {/* Component Children */}
                    {children}
                </Toolbar>
            </AppBar>
        </Box>
    );
}