import { gql } from '@apollo/client';

const LOGIN = gql`
  mutation login($email: String!, $password: String!, $identifier: String) {
    login(email: $email, password: $password, identifier: $identifier) {
      token
    }
  }
`;

const SAVE_USER = gql`
  mutation saveUser($input: SaveUserInput!) {
    saveUser(input: $input) {
      id
      displayName
      email
      inactive
      createdAt
    }
  }
`;

const UPDATE_AVATAR = gql`
  mutation updateAvatar($avatar: Upload!, $userId: ID!) {
    updateAvatar(avatar: $avatar, userId: $userId) {
      id
      displayName
      email
      inactive
      createdAt
      avatarUrl
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

const SUSPEND_USER = gql`
  mutation suspendUser($id: ID!) {
    suspendUser(id: $id)
  }
`;

const REGISTER_EMPLOYEE = gql`
  mutation registerEmployee($input: SaveUserInput!, $invitationCode: String!, $source: String!) {
    registerEmployee(input: $input, invitationCode: $invitationCode, source: $source) {
      token
    }
  }
`;

const LINK_USER_TO_STORE = gql`
  mutation linkUserToStore($invitationCode: String!, $source: String!) {
    linkUserToStore(invitationCode: $invitationCode, source: $source) {
      id
    }
  }
`;

const SEND_VERIFICATION_CODE = gql`
  mutation sendVerificationCode($email: String!) {
    sendVerificationCode(email: $email)
  }
`;

const REQUEST_RESET_PASSWORD = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;
const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!) {
    resetPassword(password: $password)
  }
`;

export {
  LOGIN,
  SAVE_USER,
  REGISTER_EMPLOYEE,
  LINK_USER_TO_STORE,
  DELETE_USER,
  SEND_VERIFICATION_CODE,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  UPDATE_AVATAR,
  SUSPEND_USER,
};
