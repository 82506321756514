import { useState } from 'react';
import { Container, Stack, Button, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import StoresQRCodesDrawer from 'src/sections/shared/StoresQRCodesDrawer';
import AlertsList from 'src/sections/shared/alerts/AlertsList';
import AlertItem from 'src/sections/shared/alerts/AlertItem';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import useResponsive from 'src/hooks/useResponsive';
import USER_ROLES from 'src/constants/userRoles';
import useAlertsFilters from 'src/hooks/useAlertsFilters';

const HomePage = () => {
  const [isStoresQRCodesDrawerOpen, setIsStoresQRCodesDrawerOpen] = useState(false);
  const isDesktop = useResponsive('up', 'sm');
  const { ACTIONS_HANDLERS } = useAlertsFilters();
  const { stores, user } = useAuth();
  const navigate = useNavigate();

  if (user.role === USER_ROLES.ADMIN) {
    navigate(PATH_DASHBOARD.admin);
  }

  const onlyOneStore = stores && stores.length === 1 ? stores[0] : null

  const handleStoresQRCodesDrawerClose = () => {
    setIsStoresQRCodesDrawerOpen(false);
  };

  const handleNavigateToAlertsList = () => {
    navigate(PATH_DASHBOARD.alerts.list);
  };

  const handleNavigateToFavoritesVideos = () => {
    navigate(PATH_DASHBOARD.alerts.list);
    ACTIONS_HANDLERS.resetAllFilters();
    ACTIONS_HANDLERS.setFavorite(true);
  };

  return (
    <Page title="Accueil">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Accueil" />
        {isDesktop && (
          <Divider
            sx={{
              mb: 5,
            }}
          />
        )}
        {/* Latest Alerts */}
        <Stack
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6">Dernières alertes</Typography>
              <Button
                variant="text"
                onClick={handleNavigateToAlertsList}
                sx={{
                  color: 'primary.main',
                  fontWeight: 'normal',
                  textTransform: 'none',
                }}
              >
                Tout voir
              </Button>
            </Stack>
            <AlertsList
              emptyContent={{
                title: 'Aucune alerte',
                description: "Aucune alerte n'a été trouvée.",
                withResetAction: false,
              }}
              AlertItemComponent={AlertItem}
              pagination={{ rowsPerPage: isDesktop ? 6 : 4 }}
            />
          </Stack>
          {/* Favorites Alerts */}
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6">Favoris</Typography>
              <Button
                variant="text"
                onClick={handleNavigateToFavoritesVideos}
                sx={{
                  color: 'primary.main',
                  fontWeight: 'normal',
                  textTransform: 'none',
                }}
              >
                Tout voir
              </Button>
            </Stack>
            <AlertsList
              AlertItemComponent={AlertItem}
              pagination={{ rowsPerPage: isDesktop ? 6 : 4 }}
              favorite
              emptyContent={{
                title: 'Aucune alerte',
                description: "Aucune alerte n'a été ajoutée aux favoris.",
                withResetAction: false,
              }}
            />
          </Stack>
        </Stack>
        {isStoresQRCodesDrawerOpen && (
          <StoresQRCodesDrawer onClose={handleStoresQRCodesDrawerClose} store={onlyOneStore} />
        )}
      </Container>
    </Page>
  );
};

export default HomePage;
